import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import ctaVideo from '../videos/cta.mp4'
import Img from 'gatsby-image'
import Vendor from '../components/vendor/vendor'

class HostScreening extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Host a Screening"
          bodyClass="body--light"
          description="Contact us to express your interest in having The Edge Film screened near you this summer"
        />
        <article>
          <div className="c-masthead">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Host a Screening</h1>
                </div>
              </div>
            </div>
            <div className="c-masthead__image-container">
              <Img
                fluid={
                  this.props.data.title.childImageSharp.fluid
                }
                alt=""
                className="c-masthead__image"
              />
            </div>
          </div>
          <div className="c-cta-content">
            <div className="container">
              <div className="row">
                <div className="c-cta-content__title-container">
                  <h2 className="c-cta-content__title">Contact us to express your interest in having The Edge Film screened near you this summer</h2>
                </div>
                <div className="c-cta-content__content-container">
                  <div className="cms-content">
                    <p>
                      If you operate a cinema, or would like to host a screening at your local cricket club or other private event, please get in touch with us to co-ordinate setting up an event.
                      Likewise, if you are a member of the public and The Edge Film is not showing in venues near you, get in touch to let us know and we will look to set up a screening in your area.
                    </p>
                  </div>
                  <a
                    href="mailto:&#105;&#110;&#102;&#111;&#064;&#116;&#104;&#101;&#101;&#100;&#103;&#101;&#102;&#105;&#108;&#109;&#046;&#099;&#111;&#046;&#117;&#107;"
                    className="c-cta-content__link c-btn c-btn--primary">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
          <Vendor className="c-vendor--inpage" />
          <div className="c-cta c-cta--nomargin-bottom">
            <video
              src={ctaVideo}
              className="c-cta__video"
              autoPlay muted playsInline loop>
            </video>
            <div className="container">
              <div className="row">
                <div className="c-cta__link-container">
                  <Link className="c-cta__link" to="/charity-partners">Our Charity Partners</Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    title: file(relativePath: { eq: "hostascreening/title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cta: file(relativePath: { eq: "cta/charity.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default HostScreening
